:root {
  --primary: #5e929c;
  --disabled-input-bg: #f4f4f4;
  --mdc-dialog-container-shape: var(--border-radius) !important;
  --mdc-snackbar-container-shape: var(--border-radius) !important;
  --border-radius: 10px;
  --mdc-shape-medium: var(--border-radius);
  --mdc-theme-primary: var(--primary) !important;
  --mdc-checkbox-selected-icon-color: var(--primary) !important;
  --font-family: 'Raleway';
  --danger: var(--bs-danger);
  --secondary: #202e82;
  --bodyHeight: 70vh;
  --mat-tab-header-active-hover-label-text-color: var(--primary);
  --mat-standard-button-toggle-shape: var(--border-radius);
}
.rounded-10 {
  border-radius: 10px !important;
}
.page-height {
  height: calc(100vh - 175px - 70px);
}
.page .pre-wrap {
  white-space: pre-wrap;
}
.pre-line {
  white-space: pre-line;
}
.w-1 {
  width: 1%;
}
.number-format-invalid {
  border: 1px solid red;
}
.smaller {
  font-size: 0.8rem;
}

.smaller2 {
  font-size: 0.6rem;
}
.not-allowed {
  cursor: not-allowed;
}
.home-versionNo {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 3px 5px;
  color: #000000b8;
  z-index: 1;
  font-size: 10px;
}
.no-padding-grid {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  margin: 0;
}
.f-600 {
  font-weight: 600;
}
.p-10 {
  padding: 10px;
}
.bg-light-2 {
  background-color: #f8f8f8;
}
.nowrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.drag-cursor {
  cursor: grabbing;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-50 {
  opacity: 0.5;
}
.color-inherit {
  color: inherit;
}
.not-visible {
  visibility: hidden;
}
.border-on-hover {
  border: 1px solid rgba(0, 0, 0, 0);
}
.border-on-hover:hover,
.border-on-hover:focus {
  border-color: rgba(0, 0, 0, 0.235);
}
.successCell {
  padding: 4px 8px;
  background-color: rgb(0, 183, 0);
  color: #eaffea;
  border-radius: var(--border-radius);
}
.pendingCell {
  padding: 4px 8px;
  background-color: rgb(252, 205, 96);
  color: #816500;
  border-radius: var(--border-radius);
}
.inertCell {
  padding: 4px 8px;
  background-color: rgb(206, 206, 206);
  color: #464646;
  border-radius: var(--border-radius);
}
.errorCell {
  padding: 4px 8px;
  background-color: #df0000;
  color: #fff2f2;
  border-radius: var(--border-radius);
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.chip {
  padding: 1px 10px;
  border: 1px solid var(--primary);
  border-radius: var(--border-radius);
  font-size: 10px;
  color: var(--primary);
}
.visibility-hide {
  visibility: hidden;
}
.input-label-hint {
  font-weight: 400;
  color: #383838;
  font-size: 13px;
}

@keyframes animateBorderPrimary {
  0% {
    border-color: var(--primary);
  }

  50% {
    border-color: var(--primary);
  }
}
.animate-border-primary {
  animation-name: animateBorderPrimary;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  border: 1px solid #dcdcdc33;
}
.text-ellipsis {
  text-overflow: ellipsis;
  /* word-wrap: normal; */
  /* height: 20px; */
  /* width: 100%; */
  overflow: hidden;
  white-space: nowrap;
}

@keyframes caution {
  0% {
    color: red;
  }
  50% {
    color: black;
  }
  100% {
    color: red;
  }
}
.caution {
  animation: caution;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  font-weight: 600;
}
.iti__country-container {
  padding-right: 20px !important;
}

.wideMatMenu.mat-mdc-menu-panel {
  max-width: 1000px !important;
  width: 1000px;
}

.cdkdrag-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 40px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}